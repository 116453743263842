import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SwipeableViewsCore from 'react-swipeable-views';

const WrappedComponent = React.forwardRef(
	function SwipeableViews({ children, index, onChangeIndex, ...props }, ref) {
		// fix when animateHeight === true react-swipeable-view bug TODO: create issue https://github.com/oliviertassinari/react-swipeable-views/issues
		useEffect(() => {
			const x = document.getElementsByClassName('react-swipeable-view-container');
			const timer = setTimeout(() => {
				x[0].style.height = 'auto';
			}, 10);

			return () => {
				clearTimeout(timer);
			};
		}, []);

		return (
			<SwipeableViewsCore
				{...props}
				ref={ref}
				index={index}
				onChangeIndex={onChangeIndex}
				containerStyle={{ minHeight: '80vh', maxWidth: '1024px' }}
				resistance
			>
				{children}
			</SwipeableViewsCore>
		);
	}
);

WrappedComponent.propTypes = {
	index: PropTypes.number.isRequired,
	onChangeIndex: PropTypes.func.isRequired
};

export default WrappedComponent;
