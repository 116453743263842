export const routes = {
	CONTACT: '/kontakt',
	REGULATIONS: '/regulamin',
	SHAREHOLDING: '/akcjonariat',
	PRODUCTS: '/faktoring/produkty',
	CONCLUSIONS: '/faktoring/kalkulator-krajowy-i-zagraniczny-wniosku',
	WELCOME_LIMIT_ADVANCED_FORM: '/faktoring/krajowy/wniosek',
	WELCOME_SELECT_ADVANCED_FORM: '/faktoring/zagraniczny/wniosek',
	LIMIT_ADVANCED_FORM: '/faktoring/krajowy/kalkulator-wniosek',
	SELECT_ADVANCED_FORM: '/faktoring/zagraniczny/kalkulator-wniosek',
	SERVER_ERROR: '/500',

	HOME: '/',
	// SEO components
	ACADEMY: '/akademia',
	ACADEMY_TEMP_ITEM: '/akademia/faktoring-w-budownictwie',
	ACADEMY_TEMP_ITEM_2: '/akademia/o-co-chodzi-z-cesja-wierzytelnosci',
	OFFERS: '/faktoring/kalkulator-krajowy-i-zagraniczny-oferty',
	LIMIT_OFFER: '/faktoring/krajowy/oferta',
	SELECT_OFFER: '/faktoring/zagraniczny/oferta',
	LIMIT_FORM: '/faktoring/krajowy/kalkulator-oferty',
	SELECT_FORM: '/faktoring/zagraniczny/kalkulator-oferty',
	FACTORING: '/faktoring/faktoring-definicja-wszystko-co-powinienes-wiedziec',
	FACTORING_COMPLETE: '/faktoring/faktoring-pelny',
	FACTORING_INCOMPLETE: '/faktoring/faktoring-niepelny',
	FACTORING_REVERSE: '/faktoring/faktoring-odwrotny'
};
