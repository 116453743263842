import { useQuery } from '@apollo/client';
import Tab from '@material-ui/core/Tab';
import { navigate } from '@reach/router';
import { GET } from 'api/queries';
import Spinner from 'components/Molecules/material-ui/Spinner';
import TabContent from 'components/Molecules/material-ui/TabContent';
import Tabs from 'components/Molecules/material-ui/Tabs';
import SwipeableViews from 'components/Organisms/SwipeableViews';
import { routes } from 'constants/routes';
import useTabs from 'hooks/useTabs';
import HeaderContext from 'providers/HeaderProvider';
import React, { useContext, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function regulations() {
	const { setHeader } = useContext(HeaderContext);

	useEffect(() => {
		setHeader({ isMain: true });
	}, []);

	const { tabs, setTabs, handleChangeTab } = useTabs(0, {});

	const { error: errorFetchTos, data: dataTos } = useQuery(GET.TERMS_OF_SERVICE, {
		fetchPolicy: 'cache-and-network'
	});

	const { error: errorFetchRodo, data: dataRodo } = useQuery(GET.RODO, {
		fetchPolicy: 'cache-and-network'
	});

	if(errorFetchTos || errorFetchRodo) navigate(routes.SERVER_ERROR);

	return (
		<div>
			{!dataTos && <div className='h-content w-full h-container flex items-center justify-center'><Spinner /></div>}

			<Tabs value={tabs.index} onChange={handleChangeTab}>
				<Tab tw='text-08 font-semibold' label="REGULAMIN" />
				<Tab tw='text-08 font-semibold' label="RODO" />
			</Tabs>

			<SwipeableViews
				index={tabs.index}
				onChangeIndex={(index) => handleChangeTab(null, index)}
				action={actions => setTabs({ actions })}
				animateHeight
			>
				<TabContent>
					{dataTos?.termsOfService && (
						<div className='px-10 pb-10'>
							<p className='w-3/5 text-15 py-12'>{dataTos?.termsOfService?.name}</p>
							<div>{ReactHtmlParser(dataTos?.termsOfService?.content)}</div>
						</div>
					)}
				</TabContent>
				<TabContent>
					{dataRodo?.rodoClause && (
						<div className='px-10 pb-10'>
							<p className='w-3/5 text-15 py-12'>{dataRodo?.rodoClause?.name}</p>
							<div>{ReactHtmlParser(dataRodo?.rodoClause?.content)}</div>
						</div>
					)}
				</TabContent>
			</SwipeableViews>
		</div>
	);
}
