import { useCallback } from 'react';
import { useSetState } from 'react-use';

export default function useTabs(value, tabsInitial) {
	const [tabs, setTabs] = useSetState({
		index: value,
		actions: null,
		...tabsInitial
	});

	const handleChangeTab = useCallback((event, newValue) => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		setTabs({ index: newValue });
		handleUpdateHeight();
	}, [tabs]);

	const handleUpdateHeight = useCallback((delay = 500) => {
		setTimeout(() => {
			if(tabs.actions) {
				tabs.actions.updateHeight();
			}
		}, delay);
	}, [tabs]);

	return {
		tabs,
		setTabs,
		handleChangeTab,
		handleUpdateHeight
	};
}
