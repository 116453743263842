import { Tabs as TabsCore } from '@material-ui/core';
import React from 'react';

{/* TODO: set maxWidth in tailwindcss */}
export default function Tabs({ value, onChange, children, ...props }) {
	return (
		<TabsCore
			style={{ maxWidth: '1024px' }}
			className='fixed w-full bg-light z-nav border-b border-solid border-grey-B bg-light mb-40'
			value={value}
			onChange={onChange}
			indicatorColor='primary'
			textColor='primary'
			variant="fullWidth"
			{...props}
		>
			{children}
		</TabsCore>
	);
}
