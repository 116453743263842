import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { val } from 'utils/helper';

export default function Spinner({ Component, size, color, ...props }) {
	const spinnerColor = color ? color : val('primary');

	return (
		<Component color={spinnerColor} size={size} {...props} />
	);
}

Spinner.defaultProps = {
	Component: PulseLoader,
	size: 5
};
